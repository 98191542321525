import { createSlice } from "@reduxjs/toolkit";

const dimensionSlice = createSlice({
  name: 'dimension',
  initialState:{
    width: window.innerWidth,
    height: window.innerHeight
  },
  reducers: {
    setWidth: (state) => {
      state.width = window.innerWidth;
    },
    setHeight: (state) => {
      state.height = window.innerHeight;
    },
    setDimension: (state) => {
      state.width = window.innerWidth;
      state.height = window.innerHeight;
    }
  }
})

export const {setWidth, setHeight, setDimension} = dimensionSlice.actions;
export default dimensionSlice.reducer