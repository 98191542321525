import React, { useContext, useEffect } from "react";
import Screen from "../../components/Screen";
import appContext from "../../app/context/appContext";
import {
  darkBackgroundMain,
  darkNavBarBorderColor,
  darkSideBarColor,
  darkTextColor,
  lightBackgroundMain,
  lightNavBarBorderColor,
  lightSidebarColor,
  lightTextColor,
} from "../../globalVariables";

const About = () => {
  const appState = useContext(appContext);
  const { currentMode } = appState;

  useEffect(() => {
    document.title = "Junbo AI - About";
  }, []);

  // Define an array of objects containing title and text for each section
  const sections = [
    {
      title: "About Junbo AI: From Data to Delivery, AI-Optimized supply chains.",
      text: `Welcome to Junbo AI, where innovation meets efficiency in the world of supply chain management. 
        As a leading provider of cutting-edge solutions, we empower businesses to streamline their operations, 
        enhance visibility, and embrace the future of supply chain excellence.`,
    },
    {
      title: "Our Vision",
      text: `At Junbo AI, we envision a future where supply chain management transcends mere efficiency 
        to become a strategic driver of success for businesses worldwide. We see a landscape where seamless 
        integration of artificial intelligence, user-centric design, and immersive technologies revolutionizes 
        the very fabric of supply chain operations.`,
    },
    {
      title: "What Sets Us Apart",
      text: `Unparalleled Expertise
        Backed by a team of industry experts and AI specialists, we bring a wealth of knowledge to the table. 
        Our experience in supply chain management allows us to understand the unique needs of businesses 
        across various sectors.
        Comprehensive Solutions
        We offer a suite of solutions designed to meet the diverse requirements of modern supply chains. 
        From our robust web-based platform to our user-friendly mobile app and groundbreaking mixed 
        reality solutions, we provide a complete ecosystem that adapts to your evolving needs.
        Innovation at Every Step
        Our commitment to innovation drives us to explore new frontiers in technology. Whether it's 
        leveraging AI for predictive analytics, developing intuitive interfaces, or pioneering mixed 
        reality applications, we stay at the forefront of advancements to keep your supply chain 
        ahead of the curve.`,
    },
    {
      title: "Our Core Values",
      text: `Customer-Centric Approach
      At the heart of everything we do is a relentless focus on our customers. We strive to understand your challenges, collaborate on solutions, and deliver an experience that exceeds your expectations.
      Integrity and Transparency
      We operate with the highest standards of integrity and transparency. You can trust us to handle your data and processes with the utmost care, ensuring the security and reliability of our solutions.
      Continuous Improvement
      In a rapidly evolving industry, we embrace a culture of continuous improvement. We welcome feedback, invest in research and development, and consistently refine our offerings to stay ahead of the dynamic nature of supply chain management.
      `,
    },
    {
      title: "Join Us in Shaping the Future",
      text: `Discover the possibilities with Junbo AI. Whether you're a small business looking to optimize your supply chain or a large enterprise seeking advanced AI solutions, we invite you to join us on this journey of transformation. Together, let's redefine the future of supply chain management.
      Contact Us to explore how our innovative solutions can elevate your supply chain experience.
      `,
    },
    // Add more sections as needed
  ];
  

  return (
    <Screen>
      <section
        style={{
          minHeight: "91vh",
          backgroundColor:
            currentMode === "light" ? lightSidebarColor : darkSideBarColor,
         
          overflowX: "hidden",
        }}
        className="section profile p-5"
      >
        <div className="row ">
          <div className="col-xl-12 p-0">
            {sections.map((section, index) => (
              <div
                key={index}
                style={{
                  backgroundColor:
                    currentMode === "light"
                      ? lightBackgroundMain
                      : darkBackgroundMain,
                  
                  color: currentMode === "light" ? lightTextColor : darkTextColor,
                  overflowX: "hidden",
                  border:"none"
                }}
                className="card "
              >
                <div className="card-body pt-3">
                  <h5
                    style={{
                      color: currentMode === "light" ? "" : darkTextColor,
                    }}
                    className="card-title"
                  >
                    {section.title}
                  </h5>
                  <div
                    className="col-lg- col-md- label"
                    style={{
                      color: currentMode === "light" ? "" : darkTextColor,
                      // border:"2px solid red"
                    }}
                  >
                    {section.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Screen>
  );
};

export default About;
