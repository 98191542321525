import React, { useContext, useState } from 'react'

import arrow_up from '../../assets/svgs/arrow-up.svg'
import arrow_down from '../../assets/svgs/arrow-down.svg'
import deleteIcon from '../../assets/svgs/delete.svg'
import trashDark from '../../assets/svgs/bin.png';

import Code from '../chat/Code'
import Loader from '../../components/Loader'
import useQuestion from './useQuestion'
import appContext from '../../app/context/appContext'
import { darkBubbleBackground, darkTextColor, lightBubbleBackground, lightTextColor } from '../../globalVariables'

const Question = ({ item, history, onDelete }) => {
  const pattern = /```([^`]+)```/g;
  const codeIdentifier = '[CODE]'
  var text = item.answer;
  const codes = text.match(pattern);
  const answer = text.replace(pattern, `\n${codeIdentifier}`);
  const appState = useContext(appContext);
  const { currentMode } = appState
  const date = new Date(item.timestamp).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const time = new Date(item.timestamp).toLocaleTimeString("en-US", {
    hour: '2-digit',
    minute: '2-digit',
  })

  const { removeQuestion } = useQuestion();

  const [show, setShow] = useState(false);
  return (
    <div style={{
      backgroundColor: currentMode == 'light' ? lightBubbleBackground : darkBubbleBackground,
      color: currentMode == 'light' ? lightTextColor : darkTextColor,
    }} className={`question d-flex flex-column gap-2 p-4 mb-4`}>
      {
        !history ? null :
          <div className='d-flex justify-content-between align-items-center gap-2'>
            <span style={{
              color: currentMode == 'light' ? lightTextColor : darkTextColor
            }} className='question-timestamp'>{date} | {time}</span>
            <div style={{ cursor: 'pointer' }} onClick={() => removeQuestion(item)}>
              <img src={currentMode == 'light' ? deleteIcon:trashDark} />
            </div>
          </div>
      }
      <div className='d-flex justify-content-between align-items-center gap-2' onClick={history ? null : () => setShow(!show)}
        style={history ? null : { cursor: 'pointer' }}>
        <span style={{
          color: currentMode == 'light' ? lightTextColor : darkTextColor
        }} className='question-title'>{item.question}</span>
        {history ? null : <img src={show ? arrow_up : arrow_down} />}
      </div>
      {
        !show && !history ? null :
          <div className='mt-4'>
            <Loader loading={!answer} color={'#3279E9'} center>
              {
                answer?.split('\n').map((msg, index) => {
                  return (
                    msg == codeIdentifier
                      ? <Code style={{ marginTop: 20 }} key={index} code={codes.shift()} />
                      : !msg ? null : <p style={{
                        color: currentMode == 'light' ? lightTextColor : darkTextColor
                      }} key={index} className='question-answer'>{msg}</p>
                  )
                })
              }
            </Loader>
          </div>
      }
    </div>
  )
}

export default Question