import { Configuration, OpenAIApi } from 'openai';
import { keys } from './keys';

const configuration = new Configuration({
    apiKey: keys.GPT_KEY
})
const openai = new OpenAIApi(configuration);

export const askGPT = async (messages) => {
    try {
        const completion = await openai.createChatCompletion({ model: "gpt-3.5-turbo", messages });
        const message = completion.data.choices;
        return message
    } catch (err) {
        alert(err.response.data.error.message)
    }
}