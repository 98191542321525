import React, { useContext } from 'react'
import appContext from '../app/context/appContext'
import { darkNavBarBorderColor, darkTextColor, lightNavBarBorderColor, lightTextColor ,
  lightBackgroundMain,
  darkBackgroundMain
} from '../globalVariables'
import { red } from '@mui/material/colors'

const Button = ({ button, className, style }) => {
  const appState = useContext(appContext)
  const { currentMode } = appState
  const onClick = () => {
    if (button.disabled) return alert(button.disableMessage)
    button.onClick()
  }
  return (
    <div style={{ border: currentMode == 'light' ? `1px solid ${lightNavBarBorderColor}` : `1px solid ${darkNavBarBorderColor}`, ...style }} className={`button ${className} d-flex flex-row align-items-center justify-content-center`} onClick={onClick} >
      <div className='d-flex flex-row align-items-center'>
        <div style={{ paddingRight: 20, borderColor:'pink',   }}>
          {!button.icon ? null : <img src={button.icon} alt='icon not show' style={{ height: 20, width: 20 ,
             
          
          }} />}
        </div>
        <span style={{ fontSize: 18, color: darkTextColor,
          //  backgroundColor: currentMode =="light" ? lightBackgroundMain : darkBackgroundMain,
                borderColor: currentMode == "light" ? lightNavBarBorderColor : darkNavBarBorderColor,
                color: currentMode == "light" ? lightTextColor : darkTextColor,
         }}>
          {button.title}
        </span>
      </div>
      <div>
        {/* right arrow */}
      </div>
    </div>
  )
}

export default Button