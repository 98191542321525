import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import questionIcon from '../../assets/svgs/question.svg';
import reload from '../../assets/svgs/reload.svg';


import useQuestion from '../questions/useQuestion';

import Screen from '../../components/Screen'
import Question from '../questions/Question';
import SubmitButton from '../../components/SubmitButton';

const Home = () => {
  const { questions } = useSelector(state => state.question)
  const data = [...(questions ? questions : [])]?.sort((a, b) => b.timestamp - a.timestamp)

  const { getQuestions, sendQuestion } = useQuestion();
  const [question, setQuestion] = useState();


  useEffect(() => {
    getQuestions()
  }, [])

  return (
    <Screen>
      <div className='d-flex flex-column mt-5 ms-5 me-5 gap-5 pb-5'>
        {/* Input */}
        <div className='row d-flex justify-content-center'>
          <div className='col-10 col-lg-6 col-xl-6'>
            <input
              className='form-control h-100 p-4'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder='Ask you questions here'
            />
          </div>
          <div className='col-2 col-lg-1 col-xl-1'>
            <SubmitButton
              style={{ height: '100%' }}
              icon={reload}
              iconStyle={{ height: 36, width: 43 }}
              color={'primary'}
              onClick={() => sendQuestion(question)
                .then(() => setQuestion(''))
                .catch(() => setQuestion(''))
              }
            />
          </div>
        </div>
        {/* Question */}
        <div className="row">
          <div className='col-12 col-lg-12 col-xl-12 d-flex align-items-center justify-content-center gap-2'>
            <img src={questionIcon} />
            <span className='page-title'>Questions</span>
          </div>
        </div>
        {/* Questions */}
        <div className='row'>
          {
            data?.map((item, index) => (
              <div className='col-12 col-lg-6 col-xl-6 mb-3'>
                <Question item={item} key={index} />
              </div>
            ))
          }
        </div>
        {/* <div className='d-flex gap-2 mt-2'>
          <div className='question-container d-flex flex-column gap-2 w-100'>
            {
              data?.map((item,index)=> index >= data?.length/2 ? null :(
                <Question item={item} key={index}/>
              ))
            }
          </div>
          <div className='question-container d-flex flex-column gap-2 w-100'>
            {
              data?.map((item,index)=> index < data?.length/2 ? null :(
                <Question item={item} key={index}/>
              ))
            }
          </div>
        </div> */}
      </div>
    </Screen>
  )
}

export default Home