import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";
import { VscThreeBars } from "react-icons/vsc";
import { RxCross2 } from "react-icons/rx";
import useAuth from "../features/auth/useAuth";
import FormModal from "./FormModal";

import logo from "../assets/images/logo.png";
import avatar from "../assets/images/avatar.png";
import appContext from "../app/context/appContext";
import {
  darkNavBarBorderColor,
  darkNavBarColor,
  darkTextColor,
  lightNavBarColor,
  lightTextColor,
} from "../globalVariables";
import { FormControlLabel, Switch } from "@mui/material";

import "../../src/Styles/Navbar/Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const { onLogout, onUpdateProfilePhoto } = useAuth();
  const appState = useContext(appContext);
  const { setShowLogin, currentMode, setCurrentMode } = appState;
  const [toggle, setToggle] = useState(false);

  // states
  const [show, setShow] = useState();
  const [image, setImage] = useState();

  const fields = [
    {
      value: image,
      setValue: (value) => setImage(value),
      required: true,
      type: "file",
    },
  ];

  const pages = [
    { title: "Home", to: "/home" },
    { title: "History ", to: "/history" },
    {
      title: "About Us",
      to: token && "/about",
      onClick: () => {
        if (!token) setShowLogin(true);
      },
    },
    { title: "Contact Us", to: "/contact" },
  ];

  return (
    <section>
      <div
        style={{
          borderBottom:
            currentMode === "light"
              ? ""
              : `1px solid ${darkNavBarBorderColor}`,
          backgroundColor:
            currentMode === "light" ? lightNavBarColor : darkNavBarColor,
          color: currentMode === "light" ? lightTextColor : darkTextColor,
        }}
        className="navbar-container row d-flex align-items-end justify-content-between pt-lg-4 pt-2 px-lg-5 px-4 ps-2 pe-4"
      >
        {/* Title */}
        <div className="d-flex justify-content-between col-12 col-lg-4 col-xl-4 " style={{}}>
          
          <div className=" col-lg-4 col-xl-4" style={{}}>
            <img
              className="bubble-icon img-fluid"
              src={logo}
              style={{ width: "60px", height: "60px" }}
              alt="logo not show"
            />
          </div>
          <div className="d-lg-none mt-1">
            {toggle ? (
              <RxCross2 onClick={() => setToggle(!toggle)} />
            ) : (
              <VscThreeBars onClick={() => setToggle(!toggle)} />
            )}
          </div>
        </div>

        {/* Pages */}



         <div
            className="col-lg-6 col-xl-7 d-lg-block d-none navbar-pages-container d-lg-flex gap-4 justify-content-end"
            style={{ }}
          >
            {pages?.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                onClick={item.onClick}
                className={`navbar-pages py-2 ${
                  item.to === location.pathname ? "navbar-selected" : ""
                }`}
              >
                {item.title}
              </Link>
            ))}
          <FormControlLabel
            style={{ padding: "0", margin: "0" }}
            control={<Switch defaultChecked={currentMode == "dark"} onChange={() => setCurrentMode(currentMode == "light" ? "dark" : "light")} />}
            label="Dark Mode"
          />
          </div>
       

        {toggle && (
          <div
            className={ `col-lg-6 col-xl-7 d-lg-none  navbar-pages-container d-flex flex-lg-row flex-column gap-lg-5 gx-4 justify-content-end ${toggle ? 'd-sm-block' : 'd-sm-none'}` }
            style={{ }}
          >
            {pages?.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                onClick={item.onClick}
                className={`navbar-pages py-2 ${
                  item.to === location.pathname ? "navbar-selected" : ""
                }`}
              >
                {item.title}
              </Link>
            ))}
            <FormControlLabel
              style={{ padding: "0", margin: "0" }}
              control={<Switch defaultChecked={currentMode == "dark"} onChange={() => setCurrentMode(currentMode == "light" ? "dark" : "light")} />}

              label="Dark Mode"
            />
          </div>
        )}
        
        {/* Profile */}
        <div className={`col-3  col-lg-1 d-lg-block col-xl-1 ps-0 pe-0 ${toggle ? 'd-block' : 'd-none'}`}
        style={{}}
        >
  <div className="d-flex pb-2 gap-4 justify-content-lg-end justify-content-center">
    <NavDropdown
      id="nav-dropdown-dark-example"
      title={
        <img
          className="navbar-profile"
          src={token ? token?.imageURL : avatar}
          alt="img not show"
        />
      }
      onClick={
        token
          ? null
          : () => {
              setShowLogin(true);
            }
      }
    >
      <div className="dropdown-options">
        <FormControlLabel
          style={{ padding: "0", margin: "0" }}
          control={<Switch defaultChecked={currentMode =="dark"} onChange={() => setCurrentMode(currentMode =="light" ? "dark" : "light")} />}

          label="Dark Mode"
        />
        {pages?.map((page, index) => (
          <NavDropdown.Item
            key={index}
            href={page.to}
            onClick={page.onClick}
          >
            {page.title}
          </NavDropdown.Item>
        ))}
      </div>
      {token && (
        <div>
          <NavDropdown.Item onClick={() => setShow(true)}>
            Upload Image
          </NavDropdown.Item>
          <NavDropdown.Divider color="white" />
          <NavDropdown.Item onClick={onLogout}>Logout</NavDropdown.Item>
        </div>
      )}
    </NavDropdown>
  </div>
</div>

        <FormModal
          show={show}
          fields={fields}
          title={"Upload Image"}
          handleClose={() => setShow(false)}
          submit={() =>
            onUpdateProfilePhoto(image)
              .then(() => setImage(undefined))
              .catch(() => setImage(undefined))
          }
        />
      </div>
    </section>
  );
};

export default Navbar;
