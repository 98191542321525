import React, { useState } from 'react'
import AppContext from './appContext'
export default function Default(props) {
    const [showLogin, setShowLogin] = useState(false)
    const [showSignup, setShowSignup] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    const [currentMode, setCurrentMode] = useState('dark')
    return (
        <AppContext.Provider value={{ currentMode,setCurrentMode, showLogin, setShowLogin,showSignup, setShowSignup,showLoader, setShowLoader }}>
            {props.children}
        </AppContext.Provider>
    )
}
