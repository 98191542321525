import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { setDimension } from '../features/dimension/slice';

// Assets
import trash from '../assets/svgs/delete.svg';
import trashDark from '../assets/svgs/bin.png';
import enter from '../assets/svgs/enter.svg';
import enterDark from '../assets/svgs/logout.png';

// Components
import useChat from '../features/chat/useChat';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import appContext from '../app/context/appContext';
import { darkNavBarBorderColor, darkSideBarColor, lightNavBarBorderColor, lightSidebarColor,
  lightTextColor,
  darkTextColor,
  lightBackgroundMain,
  darkBackgroundMain,
 } from '../globalVariables';

const Sidebar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { deleteMessages } = useChat();
  const { token } = useSelector(state => state.auth);

  const dimension = useSelector(state => state.dimension);

  const isMobile = dimension?.width <= 768;
  const appState = useContext(appContext)
  const { setShowLogin, currentMode } = appState
  useEffect(() => {
    window.addEventListener('resize', () => dispatch(setDimension()));
    return () => {
      window.removeEventListener('resize', dispatch(setDimension()));
    }
  }, []);


  const settings = [
    !token ? {
      title: 'Login',
      icon: currentMode == 'light' ? enter : enterDark,
      border:currentMode == 'light' ? lightNavBarBorderColor : darkNavBarBorderColor,
      onClick: () => {
        setShowLogin(true)
      }
    } : {
      title: 'Clear Chat',
      icon:currentMode == 'light' ?  trash: trashDark,
      border:currentMode == 'light' ? lightNavBarBorderColor : darkNavBarBorderColor,
      onClick: deleteMessages
    },
  ].filter(btn => btn)

  return (
    <>

      <div className={`sidebar ${isMobile ? '' : ' d-flex'} `} style={{ 

border: currentMode == 'light' ? `1px solid ${lightNavBarBorderColor}` : `1px solid ${darkNavBarBorderColor}`, 
backgroundColor: currentMode == 'light' ? lightSidebarColor : darkSideBarColor ,
// border:"2px solid red"
width:"90%"
}}>
<div className='d-flex flex-column justify-content-between pt-2 pb-2' style={{  }}>
  <h4 className='px-2 ' style={{
                      backgroundColor: currentMode === "light" ? lightBackgroundMain : darkBackgroundMain,
                      borderColor: currentMode === "light" ? lightNavBarBorderColor : darkNavBarBorderColor,
                      color: currentMode === "light" ? lightTextColor : darkTextColor,
                      marginTop:"93%",
                      // fontSize:"36px",
                    }}>Empowering the Supply Chain with AI Insight.</h4>
  <div className='d-flex flex-column  gap-0'>

    <div className='button-list'>
      {
        settings?.map((button, index) => {
          return <Button key={index} button={button} className={index != 0 ? '' : ''} />
        })
      }
    </div>

  </div>
</div>

</div>
    </>
  )
}

export default Sidebar