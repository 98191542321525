import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { PiAppleLogoBold, PiApplePodcastsLogoBold } from "react-icons/pi";
import auth from '../../assets/images/auth.png';
import google from '../../assets/svgs/google.svg';
import facebook from '../../assets/svgs/facebook.svg';

import useAuth from './useAuth';

import Form from '../../components/Form';
import SubmitButton from '../../components/SubmitButton';
import Button from '../../components/Button';
import { Box } from '@mui/material';
import appContext from '../../app/context/appContext';
import { darkBackgroundMain, darkTextColor, lightBackgroundMain, lightTextColor,
  darkNavBarBorderColor,
  lightNavBarBorderColor
} from '../../globalVariables';
import appleWhite from '../../assets/images/apple-white.png';
import appleblack from '../../assets/images/apple-black.png';

const Login = () => {
  const {
    loading,
    onGoogleLogin,
    onFacebookLogin,
    onAppleLogin,
    onLogin,
    onForgot,
  } = useAuth()

  const [data, setData] = useState({
    email: '',
    password: ''
  });

  const fields = [
    {
      label: 'Email Address',
      value: data.email,
      type: 'email',
      setValue: (value) => setData({ ...data, email: value }),
      placeholder: 'Enter your email'
    },
    {
      label: 'Password',
      value: data.password,
      type: 'password',
      setValue: (value) => setData({ ...data, password: value }),
      placeholder: 'Enter your password'
    },
  ]
  const appState = useContext(appContext);
  const { setShowLogin, setShowSignup, showLogin, currentState ,currentMode} = appState
  useEffect(() => {
    document.title = 'Junbo AI - Login'
  }, [])

  return (
    <Box sx={showLogin && {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1111',
    }} onClick={(e) => {
      if (e.target === e.currentTarget) {
        setShowLogin(false);
        setShowSignup(false);
      }
    }}>
      <Box sx={{
        maxHeight: '75vh',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: currentMode === "light" ? lightBackgroundMain : darkBackgroundMain,
                borderColor: currentMode === "light" ? lightNavBarBorderColor : darkNavBarBorderColor,
                color: currentMode === "light" ? lightTextColor : darkTextColor,
        overflow: 'auto',
        borderRadius: '16px',
        padding: '60px 0px',
        width: {
          xs: '99vw',
          sm: '99vw',
          md: '50vw',
          lg: '50vw',
        },
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent",
        },
        animation: `${showLogin ? 'slideIn 0.3s' : 'slideOut 0.12s'} ease-out forwards`,
      }}>
        <div className='col-lg-12 col-md-12 d-flex flex-col' >
          <div className='form-container ms-5 me-5'>
            {/* Title */}
            <h2>Sign in to Junbo AI</h2>
            {/* <p >Sign in to use Junbo AI</p> */}
            {/* Form */}
            <div><Form fields={fields} /></div>
            {/* Forgot */}
            <div className='d-flex justify-content-end w-100 mb-5'>
              <span onClick={() => onForgot(data.email)} style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }}>Forgot Password?</span>
            </div>
            {/* Submit */}
            <SubmitButton
              onClick={() => onLogin(data.email, data.password)}
              title={'Login'}
              color={'primary'}
              loading={loading}
              style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}
            />
            {/* Border Line */}
            <div className='d-flex align-items-center mt-4 mb-4'>
              <div className='full-width-border' style={{ flex: 1 }} />
              <div style={{ flex: 0.5, textAlign: 'center' }}>Or Sign in With</div>
              <div className='full-width-border' style={{ flex: 1 }} />
            </div>
            {/* Socials */}
            <div className='d-flex align-items-center justify-content-center'>
              {/* <Box sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '30%',
                  lg: '30%',
                }
              }}> */}
                <Button
                  button={{
                    title: 'Google',
                    icon: google,
                    onClick: onGoogleLogin
                  }}
                  className={'button-full-border '}
                  style={{ width: '100%' }}
                />
                <Button
                    button={{
                      title: 'Apple',
                      // icon: appleWhite,
                    icon: currentMode == 'light' ? appleblack : appleWhite,

                      onClick: onAppleLogin,
                    }}
                    className={'button-full-border '}
                    style={{ width: '100%', marginLeft:'15px', 
         
                    }}
                  />
              {/* </Box> */}
            </div>
            {/* Navigation */}
            <div className='auth-navigation d-flex justify-content-center'>
              <span style={{ color: currentMode === "light" ? lightTextColor : darkTextColor, }}>Don't have an account?</span> &nbsp;&nbsp;
              <Link className='link' onClick={() => {
                setShowSignup(true)
                setShowLogin(false)
              }}>Sign Up</Link>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  )
}

export default Login