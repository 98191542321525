export const welcomeMessages = [
  {
    answer: `Welcome! I can help you find any information, and help you write, summarize, and generate any text in seconds. Not happy with the response I gave you? Ask me to change, rewrite or rephrase any response and I'll do so instantly.`,
    timestamp: Date.now(),
    chatID: '',
    userID: ''
  },
  {
    answer: `Let's get started!`,
    timestamp: Date.now(),
    chatID: '',
    userID: ''
  },
]

export const cQuestions = [
  
  {
    question: ' enim deserunt laboris enim id magna nostrud dolor duis non velit amet eu.',
    answer: 'Consequat in occaecat esse tempor nulla ut laborum ad amet cillum duis. Id nulla consequat ut aliqua amet id voluptate aute. Est enim culpa consectetur eu dolore do pariatur est ullamco minim.'
  },
]