import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';



// Hooks
import useChat from './useChat';

import MessageList from './MessageList';
import Sidebar from '../../components/Sidebar';
import Screen from '../../components/Screen';

const Chat = () => {
  const { token } = useSelector(state => state.auth);

  // Hooks
  const { getMessages } = useChat();

  useEffect(() => {
    if (token)
      getMessages()

    document.title = 'Junbo AI - Chat'
  }, [token]);

  return (
    <Screen>
      <div className='d-flex flex-row' style={{ overflow: 'hidden', height: '100%'}}>
        <Sidebar />
        <MessageList />
      </div>
    </Screen>
  )
}

export default Chat
