import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name: 'question',
  initialState:{
    questions: []
  },
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload
    },
    newQuestion: (state, action) => {
      state.questions = [...state.questions, action.payload]
    },
    deleteQuestion: (state, action) => {
      state.questions = state?.questions.filter(item => item.chatID != action.payload.chatID)
    },
    updateQuestion: (state, action) => {
      state.questions = state?.questions.map(item=>item.chatID == action.payload.chatID ? action.payload : item)
    },
  }
})

export const {setQuestions, newQuestion, updateQuestion, deleteQuestion} = questionSlice.actions;
export default questionSlice.reducer