import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import instance from '../../config/api';


import { db } from "../../config/firebase";
import { setQuestions, newQuestion, updateQuestion, deleteQuestion } from './slice';

export default () => {
  const dispatch = useDispatch();

  const { token } = useSelector(state => state.auth);

  const [loading, setLoading] = useState();

  const userID = token?.userID;

  const getQuestions = async () => {
    try {
      db.collection('Question').where('userID', '==', userID).onSnapshot((snapshot) => {
        const data = snapshot.docs.map((item) => { return { ...item.data(), id: item.id } });
        dispatch(setQuestions(data));
      })
    } catch (err) {
      console.log(err.message);
    }
  }

  const sendQuestion = async (question) => {
    try {
      const timestamp = Date.now()
      const chatID = `${userID};${timestamp}`
      const data = {
        question,
        userID,
        chatID,
        timestamp,
        answer: '',
      }
      db.collection('Question').doc(chatID).set(data);
      dispatch(newQuestion(data));
      gptResponse(data)
    } catch (err) {
      console.log(err.message);
    }
  }

  const removeQuestion = async (question) => {
    try {
      db.collection('Question').doc(question.chatID).delete();
      dispatch(deleteQuestion(question))
    } catch (err) {
      console.log(err.message);
    }
  }

  const gptResponse = async (data) => {
    try {
      const messages = [{ role: 'user', content: data.question }];
      const response = await instance.post('/chat', { messages })
      const message = response.data[0].message;
      db.collection('Question').doc(data.chatID).set({ ...data, answer: message?.content });
      dispatch(updateQuestion({ ...data, answer: message?.content }));
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data))
      } else {
        alert(err);
      }
    }
  }

  return {
    getQuestions,
    sendQuestion,
    removeQuestion
  }
}