import React, { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';

// Config
import { welcomeMessages } from '../../config/constants';

import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/avatar.png';

// Components
import Bubble from './Bubble';
import Footer from '../../components/Footer';
import appContext from '../../app/context/appContext';
import { darkBackgroundMain, lightBackgroundMain } from '../../globalVariables';

const ChatList = () => {
  const { token } = useSelector(state => state.auth);
  const { messages } = useSelector(state => state.chat);
  const appState = useContext(appContext);
  const { currentMode } = appState
  const data = [...welcomeMessages, ...(messages ? messages : [])];

  // Scroll TO Bottom
  const bottomRef = useRef();
  const scrollToBottom = () => bottomRef.current?.scrollIntoView();
  useEffect(() => scrollToBottom(), [messages]);

  return (
    <div style={{ backgroundColor: currentMode == 'light' ? lightBackgroundMain : darkBackgroundMain }} className='d-flex flex-column justify-content-between list-container p-2 '>
      <div className='d-flex flex-column list'>
        {
          data?.map((item, index) => {
            const isUser = !!item.question;
            const icon = isUser ? (token?.imageURL ? token?.imageURL : avatar) : logo;
            return (
              <Bubble
                key={index}
                item={item}
                isUs={isUser}
                icon={icon}
              />
            )
          })
        }
        <div ref={bottomRef} />
      </div>
      <Footer />
    </div>

  )
}

export default ChatList