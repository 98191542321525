import { Box } from '@mui/material'
import React from 'react'
import { CircularProgress } from "@mui/material";
export default function Default() {
    return (
        <Box sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            zIndex: '1111',
            "&::-webkit-scrollbar": {
                width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
            },
        }}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <CircularProgress />
            </Box>
        </Box>
    )
}
