import React, { useContext, useEffect, useState } from "react";
import Screen from "../../components/Screen";
import appContext from "../../app/context/appContext";
import {
  darkBackgroundMain,
  darkNavBarBorderColor,
  darkSideBarColor,
  darkTextColor,
  lightBackgroundMain,
  lightNavBarBorderColor,
  lightSidebarColor,
  lightTextColor,
  darkInputColor
} from "../../globalVariables";

import {db} from '../../config/firebase';
const Contact = () => {
  const appState = useContext(appContext);
  const { currentMode } = appState;

  useEffect(() => {
    document.title = "Junbo AI - Contact";
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, subject, message } = formData;
   const docRef = await db.collection("contactform").add({
       name,
      phone,
      subject,
      message,
    });

    setFormData({
          name: "",
          phone: "",
          subject: "",
          message: "",
        });
   
  };
  
  return (
    <Screen>
      <section
        className="p-lg-5 p-4"
        style={{
          overflow: "scroll",
          overflowX: "hidden",
          backgroundColor: currentMode === "light" ? lightSidebarColor : darkSideBarColor,
          height: "100vh",
        }}
      >
        <div className="col-xl-12">
          <div className="row">
            <div
              className="col-lg-6 card m-auto"
              style={{
                backgroundColor: currentMode === "light" ? lightBackgroundMain : darkBackgroundMain,
                borderColor: currentMode === "light" ? lightNavBarBorderColor : darkNavBarBorderColor,
                color: currentMode === "light" ? lightTextColor : darkTextColor,
              }}
            > 
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <h5
                    className="mb-4"
                    style={{
                      backgroundColor: currentMode === "light" ? lightBackgroundMain : darkBackgroundMain,
                      borderColor: currentMode === "light" ? lightNavBarBorderColor : darkNavBarBorderColor,
                      color: currentMode === "light" ? lightTextColor : darkTextColor,
                    }}
                  >
                    Leave a Message
                  </h5>
                  <div className="row gy-3">
                    <div className="col-lg-6 col-xl-6 col-12">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder=" Your Name"
                        required
                        className={`form-control ${
                          currentMode === "light" ? "" : "textarea-placeholder"
                        } `}
                        style={{
                          backgroundColor: currentMode === "light" ? "" : darkInputColor,
                          color: currentMode === "light" ? "" : darkTextColor,
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Your Phone"
                        required
                        onKeyPress={(e) => {
                          const digitRegex = /^[0-9]*$/;
                          if (!digitRegex.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        className={`form-control ${
                          currentMode === "light" ? "" : "textarea-placeholder"
                        } `}
                        style={{
                          backgroundColor: currentMode === "light" ? "" : darkInputColor,
                          color: currentMode === "light" ? "" : darkTextColor,
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        placeholder="Subject"
                        required
                        className={`form-control ${
                          currentMode === "light" ? "" : "textarea-placeholder"
                        } `}
                        style={{
                          backgroundColor: currentMode === "light" ? "" : darkInputColor,
                          color: currentMode === "light" ? "" : darkTextColor,
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="5"
                        placeholder="Your Message"
                        required
                        className={`form-control ${
                          currentMode === "light" ? "" : "textarea-placeholder"
                        } `}
                        style={{
                          backgroundColor: currentMode === "light" ? "" : darkInputColor,
                          color: currentMode === "light" ? "" : darkTextColor,
                        }}
                      ></textarea>
                    </div>
                    <div
                      className="col-lg-12 mt-3"
                      style={{
                        backgroundColor: currentMode === "light" ? lightBackgroundMain : darkBackgroundMain,
                        borderColor: currentMode === "light" ? lightNavBarBorderColor : darkNavBarBorderColor,
                        color: currentMode === "light" ? lightTextColor : darkTextColor,
                      }}
                    >
                      <button
                        type="submit"
                        className="btn w-100 btn-primary rounded-2"
                      >
                        SEND MESSAGE
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Screen>
  );
};

export default Contact;
