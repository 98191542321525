import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import instance from '../../config/api';


import { db } from "../../config/firebase";
import useAuth from '../auth/useAuth'
import { setMessages, newMessage, clearChat } from './slice';
import { newQuestion } from '../questions/slice';
import { askGPT } from "../../config/gpt";


export default () => {
  const dispatch = useDispatch();
  const { onGoogleLogin } = useAuth();

  const { messages } = useSelector(state => state.chat)
  const { token } = useSelector(state => state.auth);

  const [loading, setLoading] = useState();
  const [message, setMessage] = useState('')

  const userID = token?.userID;

  const sendMessage = async (question, isApi) => {
    if (loading && !isApi) return;
    if (!token) return onGoogleLogin();
    try {
      setLoading(true);
      const timestamp = Date.now();
      const chatID = `${userID};${timestamp}`;
      const data = { ...(isApi ? { answer: question } : { question }), userID, timestamp, chatID };
      db.collection('Chat').doc(chatID).set(data);
      dispatch(newMessage(data));
      setMessage('')

      if (!isApi)
        await gptResponse([...messages, data])
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  }

  const getMessages = async () => {
    try {
      db.collection('Chat').where('userID', '==', userID).onSnapshot((snapshot) => {
        const data = snapshot.docs.map((item) => { return { ...item.data(), id: item.id } });
        dispatch(setMessages(data));
      })
    } catch (err) {
      console.log(err.message);
    }
  }

  const deleteMessages = async () => {
    try {
      const snapshot = await db.collection('Chat').where('userID', '==', userID).get();
      const batch = db.batch();
      snapshot.forEach((data) => {
        batch.delete(data.ref);
      })
      await batch.commit();
      dispatch(clearChat())
    } catch (err) {
      console.log(err.message);
    }
  }

  const gptResponse = async (messages) => {
    try {
      const data = messages?.map((item) => {
        const role = item.question ? 'user' : 'assistant';
        return { role, content: item.question ? item.question : item.answer }
      })
      const response = { data: await askGPT(data) };
      // const response = await instance.post('/chat', { messages: data })
      const message = response.data[0].message
      sendMessage(message?.content, true)

      // store in history
      const timestamp = Date.now()
      const chatID = `${userID};${timestamp}`
      const history = {
        question: messages[messages?.length - 1].question,
        userID,
        chatID: chatID,
        timestamp,
        answer: message?.content,
      }
      db.collection('Question').doc(chatID).set(history);
      dispatch(newQuestion(history));
    } catch (err) {
      if (err.response) {
        alert(JSON.stringify(err.response.data))
      } else {
        alert(err);
      }
    }
  }

  return {
    loading,
    message,
    setMessage,
    sendMessage,
    getMessages,
    gptResponse,
    deleteMessages
  }
}