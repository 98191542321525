import React, { useContext } from 'react'
import appContext from '../app/context/appContext'
import { darkInputColor, darkTextColor } from '../globalVariables'

const Form = ({fields}) => {
  const appState = useContext(appContext)
  const {currentMode} = appState
  return (
    <div className='form'>
      {
        fields?.map((item,index)=>{
          return item.data ? (
            <div key={index} className='d-flex w-100 gap-2'>
              {
                item.data.map((field, idx)=>{
                  return (
                    <div  className={'form-input-container '} style={{flex: 1, ...field.style}} key={idx}>
                     {field.label ? <label style={{color:currentMode =='light'? 'lightTextColor': 'lightTextColor'}} className='form-label'>{field.label}</label> : null}
                      <input
                      style={{
                        backgroundColor:currentMode == 'light' ? '':darkInputColor,
                        color:currentMode == 'light' ? '':darkTextColor,
                        
                      }}
                        className={`form-control ${currentMode == 'light' ? '':'textarea-placeholder'} ${field.type =='file' ? '' : 'form-input'} ${field.type =='file' ? '' : field.className}`}
                        type={field.type != 'text' ? field.type : 'text'}
                        value={field.type == 'file' ? undefined : field.value}
                        onChange={(e)=>field.setValue(
                          field.type == "file" ? e.target.files[0] : e.target.value
                        )}
                        placeholder={field.placeholder}
                      />
                    </div>
                  )
                })
              }
            </div>
          ) : (
            // Normal
            <div  className={'form-input-container'} key={index} style={item.style}>
              {item.label ? <label style={{color:currentMode =='light'? '': 'white'}} className='form-label'>{item.label}</label> : null}
              <input
                style={{
                  backgroundColor:currentMode == 'light' ? '':darkInputColor,
                  color:currentMode == 'light' ? '':darkTextColor,
                  
                }}
                className={`form-control ${currentMode == 'light' ? '':'textarea-placeholder'} ${item.type =='file' ? '' : 'form-input'} ${item.type =='file' ? '' : item.className}`}
                type={item.type != 'text' ? item.type : 'text'}
                value={item.type == 'file' ? undefined : item.value}
                onChange={(e)=>item.setValue(
                  item.type == "file" ? e.target.files[0] : e.target.value
                )}
                placeholder={item.placeholder}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default Form