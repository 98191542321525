import { createSlice } from "@reduxjs/toolkit";
import { welcomeMessages } from "../../config/constants";

const chatSlice = createSlice({
  name: 'chat',
  initialState:{
    messages: []
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    newMessage: (state, action) => {
      state.messages = [...state.messages, action.payload]
    },
    clearChat: (state)=>{
      state.messages = []
    }
  }
})

export const {setMessages, newMessage, clearChat} = chatSlice.actions;
export default chatSlice.reducer