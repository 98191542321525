import React from 'react'

const Code = ({code, style}) => {
  const pattern = /```([^`\n]+)/;

  const matches = code?.match(pattern);
  const text = code?.replace(pattern, '').replaceAll('```','').replace('\n','')

  const language = matches ? matches[1] : '';

  const onCopy=()=>{
    // Create a temporary element to hold the text to copy
    const tempElement = document.createElement('textarea');
    tempElement.value = text;
    tempElement.setAttribute('readonly', '');
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);

    // Select the text and copy it to the clipboard
    const selectedText = document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
    tempElement.select();
    document.execCommand('copy');

    // Remove the temporary element
    document.body.removeChild(tempElement);

    // Restore the previous selection
    if (selectedText) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selectedText);
    }
  }

  return (
    <div style={style}>
      <div className='code-header d-flex flex-row justify-content-between align-items-center'>
        <span style={{color: 'white'}}>{language}</span>
        <button onClick={onCopy} className='btn btn-secondary btn-sm'>Copy Code</button>
      </div>
      <pre className='code'>
        <span>{text}</span>
      </pre>
    </div>
  )
}

export default Code