import React, { useContext, useState } from 'react'
import Code from './Code';
import appContext from '../../app/context/appContext';
import { darkBubbleBackground, darkBubbleBackgroundIsUs, darkTextColor, lightBubbleBackground, lightBubbleBackgroundIsUs, lightTextColor } from '../../globalVariables';
import Speech from "react-text-to-speech";
import MicNoneIcon from '@mui/icons-material/MicNone';
import StopIcon from '@mui/icons-material/Stop';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ToastContainer, toast } from 'react-toastify';
const Bubble = ({ item, isUs, icon }) => {
  const appState = useContext(appContext);
  const { currentMode } = appState
  const pattern = /```([^`]+)```/g;
  const codeIdentifier = '[CODE]'
  var text = isUs ? item?.question : item?.answer;
  const codes = text?.match(pattern);
  const message = text?.replace(pattern, `\n${codeIdentifier}`)
  const messageFiltered = text?.replace(pattern, ``)
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMenu, setshowMenu] = useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFeedback = ()=>{
    toast.success("Thank you for your feedback!")
    setAnchorEl(null);
  }
  return (
    <div onMouseEnter={()=>setshowMenu(true)} onMouseLeave={()=>setshowMenu(false)} style={{ display: 'relative' }} className={`bubble-container d-flex flex-row align-items-end mt-2 ${isUs ? 'align-self-end' : 'align-self-start'}`}>
      {
        isUs ? null :
          <div className='bubble-icon d-flex align-items-center mb-1' style={{ marginRight: 10 }}>
            {icon ? <img className='bubble-icon' src={icon} /> : null}
          </div>
      }

      <div className='bubble-body card border-0'
        style={
          isUs ? {
            backgroundColor: currentMode == 'light' ? lightBubbleBackgroundIsUs : darkBubbleBackgroundIsUs,
            borderBottomRightRadius: 5,
            paddingLeft: '30px'
          } :
            {
              backgroundColor: currentMode == 'light' ? lightBubbleBackground : darkBubbleBackground,
              borderBottomLeftRadius: 5,
              paddingRight: '30px'
            }} >
        <div className='card-body'>
          {
            message?.split('\n').map((msg, index) => {
              return (
                msg == codeIdentifier
                  ? <Code key={index} code={codes.pop()} />
                  : <div style={{ display: 'relative' }}>
                    <p
                      style={
                        isUs ? { color: 'white' } :
                          {
                            color: currentMode == 'light' ? lightTextColor : darkTextColor
                          }}
                      key={index}
                      className='card-text chat'>
                      {msg}
                    </p>
                  </div>
              )
            })
          }
          <div style={{
            position: 'absolute',
            top: '50%',
            transform: 'translate(0%,-50%)',
            left: isUs ? '5px' : '',
            right: isUs ? '' : '5px'
          }}>
            {<IconButton style={{ color: currentMode == 'light' ? 'inherit' : darkTextColor }}>
              <Speech
                text={messageFiltered}
                pitch={1}
                // rate={1}
                volume={1}
                voiceURI="Microsoft Heera - English (India)"
                stopBtn={null}
                props={{ title: "React Text-To-Speech Component" }}
                onError={() => console.error("Browser not supported!")}
              >
                {({ speechStatus, start, pause, stop }) => (
                  <>
                    {console.log(speechStatus)}
                    {speechStatus !== "started" && (
                      <MicNoneIcon onClick={start}
                        style={{ backgroundColor: 'transparent' }}
                      />
                    )}
                    {speechStatus === "started" && (
                      <StopIcon onClick={stop}
                        style={{ backgroundColor: 'transparent' }}
                      />
                    )}

                  </>
                )}
              </Speech></IconButton>}
          </div>
        </div>
        {!isUs && 
        (showMenu && <div style={{
          position: 'absolute',
          top: '50%',
          transform: 'translate(0%,-50%)',
          left: isUs ? '-40px' : '',
          right: isUs ? '' : '-40px'
        }}>
          <IconButton onClick={handleClick}>
            <KeyboardArrowDownIcon sx={{ borderRadius: '50%', color: currentMode == 'light' ? lightTextColor : darkTextColor, backgroundColor: currentMode == 'light' ? lightBubbleBackground : darkBubbleBackground }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleFeedback}>
              <ThumbUpIcon sx={{ height: '20px', width: '20px', marginRight: '10px' }} />
              Thumbs Up
            </MenuItem>
            <MenuItem onClick={handleFeedback}>
              <ThumbDownIcon sx={{ height: '20px', width: '20px', marginRight: '10px' }} />
              Thumbs Down
            </MenuItem>
          </Menu>
        </div>)}
      </div>
      {
        !isUs ? null :
          <div className='bubble-icon d-flex align-items-center mb-1' style={{ marginLeft: 10, marginRight: 10 }}>
            {icon ? <img className='bubble-icon' src={icon} /> : null}
          </div>
      }
    </div>
  )
}

export default Bubble