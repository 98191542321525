export const lightBackgroundMain = 'white'
export const darkBackgroundMain = '#1D2125'
export const lightBubbleBackground = ''
export const darkBubbleBackground = '#282D33'
export const lightBubbleBackgroundIsUs = '#257AFA'
export const darkBubbleBackgroundIsUs = '#282D33'
export const lightTextColor = 'black'
export const darkTextColor = 'white'
export const lightInputColor = 'white'
export const darkInputColor = '#282D33'
export const lightSendColor = '#007bff'
export const darkSendColor = '#282D33'
export const lightSendBorderColor = '#007bff'
export const darkSendBorderColor = '#333C43'
export const lightSidebarColor = 'white'
export const darkSideBarColor = '#1D2125'
export const lightNavBarColor = ''
export const darkNavBarColor = '#1D2125'
export const darkNavBarBorderColor = '#333C43'
export const lightNavBarBorderColor = '#dee2e6'