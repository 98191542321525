import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import SubmitButton from './SubmitButton'
import useChat from '../features/chat/useChat'
import Loader from './Loader';
import appContext from '../app/context/appContext';
import { darkInputColor, darkNavBarBorderColor, darkSendBorderColor, darkSendColor, darkTextColor, lightInputColor, lightNavBarBorderColor, lightSendBorderColor, lightSendColor, lightTextColor } from '../globalVariables';
import MicNoneIcon from '@mui/icons-material/MicNone';
import { IconButton } from '@mui/material';
import { useEffect } from 'react';
const Footer = () => {
  const { token } = useSelector(state => state.auth)
  const dimension = useSelector(state => state.dimension);
  const navigate = useNavigate()

  const { loading, sendMessage, message, setMessage, deleteMessages } = useChat();
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript
  } = useSpeechRecognition();
  const startListening = () => SpeechRecognition.startListening({ continuous: true });

  const appState = useContext(appContext);
  const { setShowLogin, currentMode } = appState

  const isMobile = dimension?.width <= 768;
  useEffect(() => {
    setMessage(transcript)
  }, [transcript])
  const onSubmit = () => {
    if (!token) setShowLogin(true)
    else {
      resetTranscript()
      sendMessage(message)}
  }
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  return (
    <div className='d-flex flex-row gap-2' style={{ marginTop: 10,}}>
      <div style={{ position: 'relative', width: '100%' }}>
        <textarea
          style={{
            backgroundColor: currentMode == 'light' ? lightInputColor : darkInputColor,
            color: currentMode == 'light' ? lightTextColor : darkTextColor,
            border: currentMode == 'light' ? `1px solid ${lightNavBarBorderColor}` : `1px solid ${darkNavBarBorderColor}`,
            height:'50px'
          }}
          className={`form-control input-lg ${currentMode == 'light' ? '' : 'textarea-placeholder'}`}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder='Type your message here'
          onKeyDown={(e) => {
            if (e.keyCode == 13 && !e.shiftKey) {
              onSubmit();
            }
          }}
          rows={1}
        />
        <IconButton
          style={{
            position: 'absolute',
            bottom: '5px',
            right: '8px',
            boxShadow: listening ? '0 2px 5px rgba(0, 0, 255, 0.5)' : 'none', // Conditional shadow
          }}
          onTouchStart={startListening}
          onMouseDown={startListening}
          onTouchEnd={SpeechRecognition.stopListening}
          onMouseUp={SpeechRecognition.stopListening}
          aria-label="send"
        >
          <MicNoneIcon
            style={{
              color: listening ? 'rgba(0, 0, 255, 0.5)' : currentMode === 'light' ? lightTextColor : darkTextColor,
            }}
          />
        </IconButton>
      </div>
      {
        !isMobile || !token ? null :
          <SubmitButton
            title={"Clear Chat"}
            color={'danger'}
            onClick={deleteMessages}
            width={80}
          />
      }
      <button
        style={{
          backgroundColor: currentMode == 'light' ? lightSendColor : darkSendColor,
          borderColor: currentMode == 'light' ? lightSendBorderColor : darkSendBorderColor,
        }}
        className='btn btn-primary btn-lg'
        disabled={loading || (!message && token)}
        onClick={onSubmit}
      >
        <Loader style={{height:'20px'}} loading={loading} center>
          <i className="bi bi-send-fill"></i>
        </Loader>
      </button>
    </div>
  )
}

export default Footer