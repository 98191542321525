import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { publicRoutes, protectedRoutes } from "./config/routes";
import NoPage from "./components/NoPage";
import './App.css'
import Login from './features/auth/Login'
import Singup from './features/auth/Signup'
import { useContext, useEffect, useState } from "react";
import appContext from "./app/context/appContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StartLoader from './components/startLoader'
const Redirect = ({ element }) => {
  const { token } = useSelector((state) => state.auth);
  return element;
  // return !token ? <Navigate to={"/login"} /> : element;
};

function App() {
  const appState = useContext(appContext);
  const { showLoader,setShowLoader } = appState
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false)
    }, 1000);
  }, [])

  return (
    <>
      <Login />
      <Singup />
      <ToastContainer />
      {showLoader && <StartLoader />}
      <Routes>
        {publicRoutes?.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        {protectedRoutes?.map((route, index) => (
          <Route
            key={index}
            {...route}
            element={<Redirect {...route} />}
          />
        ))}

        <Route
          path="/"
          element={<Redirect element={<Navigate to="/home" />} />}
        />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  );
}

export default App;
