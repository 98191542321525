import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signInWithPopup, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { login, logout } from './slice';
import { db, auth, googleProvider, facebookProvider, storage, firebaseStorage, appleProvider } from '../../config/firebase';
import appContext from '../../app/context/appContext';

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [socialLoading, setSocialLoading] = useState();
  const { token } = useSelector(state => state.auth);
  const appState = useContext(appContext);
  const { setShowLogin, setShowSignup, setShowLoader } = appState
  const uploadFile = async (file, passed) => {
    var url = ''
    if (passed) {
      const fileRef = firebaseStorage.refFromURL(passed);
      await fileRef.put(file, { type: file.type });
      url = await getDownloadURL(fileRef);
    } else {
      const storageRef = ref(storage, `/ProfileImages/${Date.now().toString()}-${token.name}`);
      const response = await uploadBytes(storageRef, file);
      url = await getDownloadURL(response.ref);
    }
    return url;
  };

  const onSocialLogin = async (provider) => {
    setSocialLoading(true);
    try {
      const res = await signInWithPopup(auth, provider);
      console.log(res)
      var token = {
        email: res.user?.email,
        imageURL: res.user?.photoURL,
        name: res.user?.displayName,
        userID: res.user?.uid
      }
      // if user already exists, use that data
      const user = await (await db.collection('Users').doc(token.userID).get()).data()
      if (user) token = user;
      // Store in Database
      else db.collection('Users').doc(token.userID).set(token);

      // store in redux
      dispatch(login(token))
      setShowLogin(false);
      setShowSignup(false);
      setSocialLoading(false);
      setShowLoader(true)
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    } catch (err) {
      setSocialLoading(false);
      alert(err.message);
    }
  }

  const onLogin = async (email, password) => {
    setLoading(true);
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const token = (await db.collection('Users').doc(res.user.uid).get()).data();
      // store in redux
      dispatch(login(token))
      setShowLogin(false);
      setShowSignup(false);
      setLoading(false);
      setShowLoader(true)
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  }
  const onSignup = async (data) => {
    setLoading(true);
    try {
      if (!data.name) return alert('Kindly enter your name');
      if (!data.email) return alert('Kindly enter your email');
      if (!data.password) return alert('Kindly enter your password');
      if (!data.confirmPassword) return alert('Kindly enter your confirm password');
      if (data.confirmPassword != data.password) return alert(`Password and Confirm Password don't match`);

      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      const token = {
        email: res.user.email,
        imageURL: process.env.REACT_APP_PROFILE_IMAGE,
        name: data.name,
        userID: res.user.uid
      }
      console.log(token)
      db.collection('Users').doc(res.user.uid).set(token);

      // store in redux
      dispatch(login(token))
      setShowLogin(false);
      setShowSignup(false);
      setLoading(false);
      setShowLoader(true)
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  }

  const onLogout = async () => {
    try {
      setLoading(true);
      const response = await signOut(auth);
      dispatch(logout())
      setLoading(false);
      setShowLoader(true)
      setTimeout(() => {
        setShowLoader(false)
      }, 1000);
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  }

  const onForgot = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert(`Password reset email has been sent to ${email}`);
    } catch (err) {
      alert(err.message);
    }
  }

  const onUpdateProfilePhoto = async (image) => {
    try {
      // Upload Image
      const isUpdate =
        token?.imageURL != process.env.REACT_APP_PROFILE_IMAGE &&
        !token?.imageURL.includes('https://graph.facebook.com') &&
        !token?.imageURL.includes('https://lh3.googleusercontent.com/a');
      const imageURL = await uploadFile(image, isUpdate ? token?.imageURL : null);
      // Update Database
      await db.collection('Users').doc(token.userID).update({ imageURL });
      const newToken = { ...token, imageURL };
      dispatch(login(newToken))
    } catch (err) {
      alert(err.message);
    }
  }

  return {
    loading,
    socialLoading,
    onGoogleLogin: () => onSocialLogin(googleProvider),
    onFacebookLogin: () => onSocialLogin(facebookProvider),
    onAppleLogin: () => {
      appleProvider.addScope('email')
      appleProvider.addScope('name')
      onSocialLogin(appleProvider)
    },

    onLogin,
    onSignup,

    onLogout,
    onForgot,

    onUpdateProfilePhoto,
  }
}