// Public Pages
import Login from "../features/auth/Login";
import Signup from "../features/auth/Signup";

// Protected Pages
import Chat from "../features/chat/Chat";
import Home from "../features/home/Home";
import History from "../features/home/History";
import About from "../features/home/About";
import Contact from "../features/home/Contact";

export const protectedRoutes = [
  { path: "/home", element: <Chat /> },
  { path: "/history", element: <History /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/chat", element: <Home /> },
];

export const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> }
];
