import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import { cQuestions } from '../../config/constants';
import questionIcon from '../../assets/svgs/question.svg';
import reload from '../../assets/svgs/reload.svg';


import useQuestion from '../questions/useQuestion';

import Screen from '../../components/Screen'
import Question from '../questions/Question';
import Form from '../../components/Form';
import SubmitButton from '../../components/SubmitButton';
import appContext from '../../app/context/appContext';
import { darkBackgroundMain, lightBackgroundMain } from '../../globalVariables';

const History = () => {
  const { questions } = useSelector(state => state.question)
  const data = [...questions]?.sort((a, b) => b.timestamp - a.timestamp)

  const { getQuestions } = useQuestion();
  const appState = useContext(appContext);
  const { currentMode } = appState
  useEffect(() => {
    getQuestions()
    document.title = 'Junbo AI - History'
  }, [])

  return (
    <Screen>
      <div className=' d-flex flex-column gap-5 p-5'
        style={{
          backgroundColor: currentMode == 'light' ? lightBackgroundMain : darkBackgroundMain,
          overflow: 'scroll',
          overflowX: 'hidden',
          height: '100%'
        }}>
        {/* Question */}
        <div className='row'>
          <div className='col-12 col-lg-12 col-xl-12 d-flex align-items-center justify-content-center gap-2'>
            <span className='page-title'>History</span>
          </div>
        </div>
        {/* Questions */}
        <div className='row'>
          {
            data?.map((item, index) => (
              <div key={index} className='col-12 col-lg-6 col-xl-6 mb-3'>
                <Question item={item} history />
              </div>
            ))
          }
        </div>
      </div>
    </Screen>
  )
}

export default History